<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row align-items-center">
            <div class="col-3">
            </div>
            <div class="col-9 text-right">
              <CButton color="success" square size="sm"
                @click="openModalEvent('create','contentGroupForm', null, 'Yeni İçerik Grubu Ekle')"
              >
                Yeni Kayıt Ekle
              </CButton>
            </div>
          </div>
        </CCardHeader>
      
        <CCardBody>
          <CDataTable
            :items="contentGroups"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            :loading="loading"
            clickable-rows
            @row-double-clicked="openModalEvent('update','contentGroupForm', lastItem, 'Grup Güncelleme Ekranı ('+ lastItem.name+')')"
            v-on:refresh="contentGroupList"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem
                    @click="openModalEvent('update','contentGroupForm', item, 'Grup Güncelleme Ekranı ('+ item.name+')')">
                    Düzenle
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('delete','confirm', item, 'Grup Silme Ekranı ('+ item.name+')', item.name+' isimli içeriği silmek üzeresiniz bunu yapmak istediğinizden emin misiniz?')">
                    Kaydı Sil
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>

          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      :size="size"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      ref="form"
    />
  </CRow>
</template>
<script>

import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'


export default {
  name: 'Content',
  components: {
     ConfirmModal, FormModal
  },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'content',
      form: 'contentGroupForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openModalConfirm: false,
      openSidebar: false,
      lastItem: {},
      size: 'sm',
      fields: [
        {key: 'show_details', label: 'İşlemler', _style: 'font-size:12px'},
        {key: 'displayOrder', label: 'Sıra', _style: 'font-size:12px'},
        {key: 'name', label: 'İsim', _style: 'font-size:12px'},

      ],
      activePage: 1,
      pages: 0,
      
    }
  },
  computed: {
    contentGroups(){
      return this.$store.getters.contentGroups;
    },
    loading(){
      return this.$store.getters.contentLoading;
    }
  },
  methods: {
    async openModalEvent(actionType, form, data, title, desc){
      this.confirmSize = 'sm'
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
      
    },

    async contentGroupList() {
      await this.$store.dispatch('contentGroup_list')
    },

    async crud(item, actionType) {
      var formData = new FormData();

      formData.append('actionType', actionType);

      if(item.id){
        formData.append('id',  item.id);
      }

      if(item.displayOrder) {
        formData.append('displayOrder',  item.displayOrder);
      }

      if (actionType !== 'delete') {
        formData.append('name', item.name);
      }

      await this.$store.dispatch('contentGroup_action', formData)


      if (this.$store.getters.contentStatus.success) {
        this.openModalEvent();
        this.contentGroupList();
      }
    },
  },
  created() {
    this.contentGroupList();
  }
}
</script>
